/**
 * Cooldrops UI JS
 *
 * Commerce functions
 *
**/

(function($, Drupal, window,document){

  "use strict";

  // set namespace for UI javascript
  if (typeof window.cooldropsUI == 'undefined') { window.cooldropsUI = {}; }

  var self = window.cooldropsUI;

  ///////////////////////////////////////////////////////////////////////
  // Cache variables available across the namespace
  ///////////////////////////////////////////////////////////////////////

  //

  ///////////////////////////////////////////////////////////////////////
  // Behavior for Commerce: triggers
  ///////////////////////////////////////////////////////////////////////

  Drupal.behaviors.cooldropsUICommerce = {
    attach: function (context, settings) {

      var headerCart = $('.header-top .block--commerce-cart, header .block--commerce-cart'),
          filters = $('.pane-facetapi'),
          checkoutProgress = $('.commerce-checkout-progress');

      // open/close cart in header
      if (headerCart.length) self.dropdownCart(headerCart);

      // open/close filters in sidebar
      if (filters.length) self.dropdownFilters(filters);

      //
      if (checkoutProgress.length) self.checkoutProgress(checkoutProgress);
    }
  };

  ///////////////////////////////////////////////////////////////////////
  // Commerce functions
  ///////////////////////////////////////////////////////////////////////


  /**
   * Open cart in header
   *
   * parameters:
   *
   */
  self.dropdownCart = function(cart) {

    var menu =  cart.find('.cart-inner'),
      trigger = cart.find('.line-item-quantity a');

    // if there's a cart

    if (menu.length) {

      trigger.once('js-once-dropdown').each(function() {

        var trigger = $(this);

        // touch event to open/close
        // includes touch detection

        trigger.on('touchstart', function(e) {
          self.touch = true;

          if (cart.hasClass('js-open')) {
            self.cartClose(cart);
          } else {
            self.cartOpen(cart);
          }

          e.preventDefault();
        });

        // reset the touch variable afterwards

        trigger.on('touchend', function(e) {

          // end
          setTimeout(function() {
              self.touch = false; // reset bc we might be on a device that has mouse as well as touch capability
          }, 500); // time it until after a 'click' would have fired on mobile (>300ms)

          e.preventDefault();

        });


        // open/close on hover
        // if not in touch modus

        trigger.on('mouseenter', function(e) {

          // if no touch triggered
          if (!self.touch) {

            self.cartOpen(cart);

            e.preventDefault();

          }

        });

        cart.on('mouseleave', function(e) {

            self.cartClose(cart);

          e.preventDefault();
        });

      });
    }

    // on window resize, reset the menu to closed state

    var updateLayout = function(e) {
      self.cartClose(cart);
    };

    window.addEventListener('resize', updateLayout, false);
  };

  self.cartOpen = function(cart)
  {
    cart.addClass('js-open');
  };

  self.cartClose = function(cart)
  {
    cart.removeClass('js-open');
  };

  /**
   * Open filters in sidebar
   *
   * parameters:
   *
   */
  self.dropdownFilters = function(filters) {

    // if there's filters

    if (filters.length) {

      filters.once('js-once-dropdown').each(function() {

        var filter = $(this),
            trigger = filter.find('h2');

        // touch event to open/close
        // includes touch detection

        trigger.on('touchstart', function(e) {
          self.touch = true;

          if (filter.hasClass('js-open')) {
            self.filtersClose(filter);
          } else {
            self.filtersOpen(filter);
          }

          e.preventDefault();
        });

        // reset the touch variable afterwards

        trigger.on('touchend', function(e) {

          // end
          setTimeout(function() {
              self.touch = false; // reset bc we might be on a device that has mouse as well as touch capability
          }, 500); // time it until after a 'click' would have fired on mobile (>300ms)

          e.preventDefault();

        });

        // click to open/close

        trigger.on('click', function(e) {

          // if no touch triggered
          if (!self.touch) {
            if (filter.hasClass('js-open')) {
              self.filtersClose(filter);
            } else {
              self.filtersOpen(filter);
            }
            e.preventDefault();
          }

        });

        // click outside to close

        // $(document).mouseup(function (e) {

        //   if (filter.hasClass('js-open')) {

        //     // if the target of the click isn't the container...
        //     // ... nor a descendant of the container
        //     if (!filter.is(e.target) && filter.has(e.target).length === 0)
        //     {
        //       self.filtersClose(filter);
        //     }
        //   }
        // });
      });
    }

    // on window resize, reset the menu to closed state

    var updateLayout = function(e) {
      filters.each(function() {
        self.filtersClose($(this));
      });
    };

    window.addEventListener('resize', updateLayout, false);
  };

  self.filtersOpen = function(el)
  {
    el.addClass('js-open');
  };

  self.filtersClose = function(el)
  {
    el.removeClass('js-open');
  };

  /**
   * Wrap progress items content
   *
   * parameters:
   *
   */
  self.checkoutProgress = function(checkoutProgress) {
    checkoutProgress.once('js-once-checkout-prog-item').find('li').each(function() {

      var item = $(this);
      item.wrapInner('<span class="contents"></span>');

    });
  };

})(jQuery, Drupal, window, document);
