/**
 * Cooldrops UI JS
 *
 * Helper functions
 *
**/

(function($, Drupal, window,document){

  "use strict";

  // set namespace for UI javascript
  if (typeof window.cooldropsUI == 'undefined') { window.cooldropsUI = {}; }

  var self = window.cooldropsUI;


 ///////////////////////////////////////////////////////////////////////
  // Cache variables available across the namespace
  ///////////////////////////////////////////////////////////////////////

  self.html = $('html');
  self.body = $('body');
  self.touch = false;
  self.screen = '';

  ///////////////////////////////////////////////////////////////////////
  // Behavior for Base: triggers
  ///////////////////////////////////////////////////////////////////////

  Drupal.behaviors.cooldropsUIHelpers = {
    attach: function (context, settings) {

      // Find out if we're on a small device (phone)

      self.checkScreenSize();

      $(window).on('resize', function() {
          self.checkScreenSize();
      });

      // Test for flexboxtweener browsers, such as IE10
      if (typeof Modernizr != 'undefined') {
        Modernizr.addTest('flexboxtweener', Modernizr.testAllProps('flexAlign', 'end', true));
      }

    }
  };

  ///////////////////////////////////////////////////////////////////////
  // Helper functions
  ///////////////////////////////////////////////////////////////////////


  /**
   *
   * Find out if we're on a small device (phone)
   *
   **/
  self.checkScreenSize = function () {

      var currentBreakpoint = self.getBreakpoint();

      if (currentBreakpoint == 'bp-xs') {
        self.screen = 'xs';
      }

      if (currentBreakpoint == 'bp-sm') {
        self.screen = 'sm';
      }

      if (currentBreakpoint == 'bp-md') {
        self.screen = 'md';
      }

      if (currentBreakpoint == 'bp-lg') {
        self.screen = 'lg';
      }
  };

  /*
   * Get the current breakpoint
   * Refers to the content of the body::after pseudo element (set in set-breakpoints.scss)
   * call with window.cooldropsUI.helpers().getBreakpoint().
   */
  self.getBreakpoint = function () {
    var tag = window.getComputedStyle(document.body, ':after').getPropertyValue('content');
    // Firefox bugfix
    tag = tag.replace(/"/g,'');
    return tag.replace(/'/g,'');
  };

  /*
   * Get the top scroll position
   */
  self.getScrollTop = function() {

      // http://stackoverflow.com/questions/2506958/how-to-find-in-javascript-the-current-scroll-offset-in-mobile-safari-iphon
      var scrollTop;

      // top pos for touch devices
      if (Modernizr.touch) {
          scrollTop = window.pageYOffset;
      // for desktop
      } else {
          scrollTop = $(window).scrollTop();
      }

      return scrollTop;
  };

  /**
   * Detect if all the images withing your object are loaded
   *
   * No longer needs imagesLoaded plugin to work
   */
  self.imgLoaded = function (el, callback)
  {
    var img = el.find('img'),
      iLength = img.length,
      iCount = 0;

    if (iLength) {

      img.each(function() {

        var img = $(this);

        // fires after images are loaded (if not cached)
        img.on('load', function(){

          iCount = iCount + 1;

          if (iCount == iLength) {
            // all images loaded so proceed
            callback();
          }

        }).each(function() {
          // in case images are cached
          // re-enter the load function in order to get to the callback
          if (this.complete) $(this).load();
        });

      });

    } else {
      // no images, so we can proceed
       return callback();
    }
  };

  /**
   * Function to scroll smoothly to an anchor in the page
   *
   *
   * @el = required!, jquery object, element to scroll to
   * @offset = not required, offset the landing position or set to 'bottom' to scroll to bottom of the element
   * speed = not required, speed with wich to scroll
   * @callback = callback function that can be invoked after scrollto is done
   */
  self.scrollTo = function(el, offset, speed, callback) {

    var pos = el.offset().top;

    if (typeof offset === 'undefined') offset = 0;
    else if (offset === 'bottom') pos = el.offset().top + el.outerHeight();
    if (typeof speed === 'undefined') speed = 1000;
    if (typeof callback === 'undefined') callback = function() {};

    $('html, body').stop().animate({scrollTop: pos + offset}, speed, callback);
  };

  /*
   * Round numbers to x decimals
   * http://www.jacklmoore.com/notes/rounding-in-javascript/
   */
  self.round = function (value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  };

})(jQuery, Drupal, window, document);
