/**
 * Cooldrops UI JS
 *
 * 2 Behaviors: cooldropsUIBase + cooldropsUIForms
 * contains: triggers for functions
 * Functions themselves are split off and grouped below each behavior
 *
 * Drupal behaviors:
 *
 * Means the JS is loaded when page is first loaded
 * + during AJAX requests (for newly added content)
 * use jQuery's "once" to avoid processing the same element multiple times
 * http: *api.jquery.com/one/
 * use the "context" param to limit scope, by default this will return document
 * use the "settings" param to get stuff set via the theme hooks and such.
 *
 *
 * Avoid multiple triggers by using jQuery Once
 *
 * EXAMPLE 1:
 *
 * $('.some-link', context).once('js-once-my-behavior').click(function () {
 *   // Code here will only be applied once
 * });
 *
 * EXAMPLE 2:
 *
 * $('.some-element', context).once('js-once-my-behavior').each(function () {
 *   // The following click-binding will only be applied once
 * * });
 */

(function ($, Drupal, window, document) {

  "use strict";

  // set namespace for frontend UI javascript
  if (typeof window.cooldropsUI == 'undefined') { window.cooldropsUI = {}; }

  var self = window.cooldropsUI;

  ///////////////////////////////////////////////////////////////////////
  // Cache variables available across the namespace
  ///////////////////////////////////////////////////////////////////////

  // there are some already set in helpers.js

  // track if scrollHandling has already been fired to prevent multiples
  self.scrollHandlingVar = false;


  ///////////////////////////////////////////////////////////////////////
  // Behavior for Base: triggers
  ///////////////////////////////////////////////////////////////////////

  Drupal.behaviors.cooldropsUIBase = {
    attach: function (context, settings) {

      // Cache variables
      var messages = $(".drupal-messages .messages", context),
          tabs = $('nav.tabs').first(),
          searchBlock = $('.header-top__search--form, .header__search--form, .nav-content .block--search--form', context),
          mainNav = $('.nav-primary__system--main-menu, .header__system--main-menu', context),
          navWrapper = $('.wrapper-navigation', context),
          tables = $('table', context),
          stickyTop = $('.sticky-top', context),
          stickySidebar = $('.col--aside--right'),
          headerTop = $('.header-top', context),
          rowBottom = $('.row--bottom', context),
          doormat = $('.doormat'),
          hamburger = $('.wrapper-navigation .nav-title', context),
          faq = $('.overview-faq-accordions .node-faq, .view-faq-accordions .node-faq', context),
          teasers = $('.coolflex__ds--image-w-content'),
          CTATargetSelector = '.bean-image-background-cta-bean .field-name-title-field, .bean-image-background-cta-bean .field-type-text-long ',
          CTAImageSelector = '.bean-image-background-cta-bean .field-name-bg-imagebk-cta-bean div',
          galleryImages = $('.field-name-field-images-gallery'),
          iFrames = $('iframe'),
          cke = $('.cke_show_borders, .field-type-text-with-summary, .field-type-text-long, .views-field-body, .rte'),
          newsTags = $('.view-article-tags');

      // Click away Drupal messages
      self.drupalMessages(messages);

      // smooth scrolling to anchors
      // true if fixed header, false for regular header
      self.scrollToAnchor(0, true);

      // Make responsive tables
      // in the Sass: choose between 2 types: 'reformatted' or 'overflow'
      if (tables.length) self.setResponsiveTables(tables);

      // fluid iframes
      if (iFrames.length) self.setResponsiveFrames(iFrames);

      // Mobile menu
      if (hamburger.length && navWrapper.length) self.mobileMenu(hamburger, navWrapper);

      // Dropdown menu for navigation
      // parameters:
      // 1: navigation wrapper
      // 2: 'click' or 'hover' to open the dropdowns
      // 3: do we need a seperate icon as a trigger?
      //    If not, the highest level link itself is blocked on touch
      //    because otherwise it would click/touch through instead of opening the submenu
      if (mainNav.length) self.dropdownMenu(mainNav, 'hover', true);

      // Search in header settings
      // Options:
      //  - true: only icon, clicking it will show the input field and a cancel-icon
      //  - false: field + icon, the default way of displaying a search form
      if (searchBlock.length) self.searchHeader(searchBlock, true);

      // Set an element fixed to top while scrolling e.g fixed header
      // using custom JS
      if( stickyTop.length ) self.fixedTop(stickyTop, headerTop);

      // Fixed sidebar
      // if (stickySidebar.length) {
      //   var boundary = doormat;
      //   if (rowBottom.length) boundary = rowBottom;
      //   self.fixedSidebar(stickySidebar, boundary);
      // }

      // Open/close FAQ items
      //
      if (faq.length) self.faqCollapsable(faq);

      // make teaser images clickable, if no link around them but has a 'read more'
      if (teasers) self.teaserLink(teasers);

      // Set a class on content based on the color of the background
      if ( $(CTATargetSelector).length && $(CTAImageSelector).length) self.backgroundCheck(CTATargetSelector, CTAImageSelector);

      // add a class to body to indicate the page has edit tabs so we can set it fixed
      if (tabs.length) self.body.addClass('has-tabs');

      // Fake lazy load of Masonry images
      if (settings.masonry && typeof settings.masonry != 'undefined') self.fakeLazyLoad(100);

      // remove inline img style dimensions from CKEditor
      // especially for images inserted usine IMCE button
      if (cke.length) self.imgCaption(cke);

      // turn tag display into a dropdown list
      if (newsTags.length) {
        self.tagsToDropdown(newsTags);
      }

      /**
       * Parallax js
       */
      var slideFirst = $('.row--visual', context);
      var slideFirstBase = slideFirst.find('.parallax__layer--base');
      var slideLast = $('.row--contact', context);
      var slideLastBase = slideLast.find('.parallax__layer--base');

      var slideMiddle = $('.row--02, .row--05', context);
      var slideMiddleBase = slideMiddle.find('.parallax__layer--base');
      var slideMiddleBack = slideMiddle.find('.parallax__layer--back');

      // set the slide layers and their properties

      // first slide: fade out the text when scrolling away

      if (slideFirst.length) {
        slideFirstBase.parallax({
          parent: '.row--parallax',
          start: 'pageTop',
          end: 'slideOut',
          effect: 'opacity',
          properties: {
            start: 1,
            end: 0
          }
        });
      }

      // last slide: fade in the text when scrolling in

      if (slideLast.length) {
        slideLastBase.parallax({
          parent: '.row--parallax',
          start: 'slideIn',
          end: 'pageTop',
          effect: 'opacity',
          properties: {
            start: 0,
            end: 1
          }
        });
      }

      // Middle slides all get the same effect

      if (slideMiddle.length) {

        // fade in the text when entering
        // fade out the text when leaving
        // parallax the text
        // parallax the bg
        /* slideMiddleBase.parallax({
          parent: '.row--parallax',
          multiple: {
            s05: {
              start: 'slideIn',
              end: 'pageTop',
              effect: 'translate',
              properties: {
                start: 0,
                end: 1
              }
            },

            s02: {
              start: 'pageBottom',
              end: 'slideOut',
              effect: 'opacity',
              properties: {
                start: 1,
                end: 0
              }
            }
          }
        }); */

        slideMiddleBack.parallax({
          parent: '.row--parallax',
          multiple: {
            /* s03: {
              start: 'slideIn',
              end: 'slideOut',
              effect: 'translate',
              properties: {
                depth: 2,
                scale: true
              }
            }, */

            // second slide: parallax the bg
            s05: {
              start: 'slideIn',
              end: 'slideOut',
              effect: 'translate',
              properties: {
                depth: 4,
                scale: true
              }
            }
          }
        });
      }
      /**
       * Custom jQuery plugin for gallerysliders
       *
       * 1) load the libs: move.js and swipe.js, in de .info file of your theme
       * 2) load the plugin: galleryslider.js, in de .info file of your theme
       * 3) rename the template field--gallery.tpl.php as per the theme suggestions,
            to use this file as a custom tpl for the image field
            for brands use the view and node tpl's in the templates folder
       * 4) select the right styling in Sass config variable: $gallery-type: 'custom'
            and load the _field-images-gallery.scss Sass file
       * 5) initiate this javascript for your images wrapper like this:
       */

      var gallerySlider = $('.field-name-field-image .js-gallery');
      var productImageSlider = $('.field-name-field-product-image .js-gallery');
      var newsSlider = $('.news-carousel .js-gallery');
      var testimonialSlider = $('.view-testimonial-carousel .js-gallery');

      if (gallerySlider.length) {
        gallerySlider.find('.gallery__section').each(function() {
            var section = $(this);
            if (section.hasClass('gallery__section--thumbs')) {
              section.gallerySlider({
                single: false,
                itemsPhone: 4,
                itemsPhoneBig: 6,
                itemsTablet: 4,
                itemsDesktop: 6,
                pages: true,
                ratio: 2/3, // match the ratio of the image style
                animationType: 'slide',
                halfslide: false
              });
            } else {
              section.gallerySlider({
                //cycle: true, if you need autoplay
                animationType: 'slide'
              });
            }
        });
      }

      if (productImageSlider.length) {
        productImageSlider.find('.gallery__section').each(function() {
            var section = $(this);
            if (section.hasClass('gallery__section--thumbs')) {
              section.gallerySlider({
                single: false,
                itemsPhone: 1,
                itemsPhoneBig: 2,
                itemsTablet: 3,
                itemsDesktop: 3,
                pages: true,
                ratio: 3/3, // match the ratio of the image style
                animationType: 'slide',
                halfslide: false
              });
            } else {
              section.gallerySlider({
                //cycle: true, if you need autoplay
                animationType: 'slide'
              });
            }
        });
      }

      if (newsSlider.length) {
        newsSlider.find('.gallery__section').each(function() {
          var section = $(this);
          section.gallerySlider({
            //cycle: true, if you need autoplay
            single: false,
            itemsPhone: 1,
            itemsPhoneBig: 2,
            itemsTablet: 3,
            itemsDesktop: 3,
            pages: true,
            //ratio: 1/1,
            animationType: 'slide',
            halfslide: false,
            itemClass: '.node'
          });
        });
      }

      if (testimonialSlider.length) {
        testimonialSlider.find('.gallery__section').each(function() {
          var section = $(this);
          section.gallerySlider({
            //cycle: true, if you need autoplay
            single: false,
            itemsPhone: 1,
            itemsPhoneBig: 1,
            itemsTablet: 2,
            itemsDesktop: 2,
            pages: true,
            animationType: 'slide',
            halfslide: false,
            itemClass: '.node'
          });
        });
      }
    }
  };

  ///////////////////////////////////////////////////////////////////////
  // Behavior for Base: functions
  ///////////////////////////////////////////////////////////////////////

  /**
   * Click away Drupal messages
   *
   */
  self.drupalMessages = function (messages) {

    messages.once('js-once-messages').each(function() {

      var messages = $(this);

      messages.find('.close').on('click', function (e) {
        $(this).parents('.messages').addClass('closing');
        e.preventDefault();
      });

      messages.bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
        if ($(this).hasClass('closing')) {
          $(this).addClass('closed');
        }
      });
    });
  };

  /**
   * Scroll to anchor in page
   *
   * headerFixed: is there a fixed header or not? Boolean
   */
   self.scrollToAnchor = function(offset, headerFixed) {

    // on page load, check if hash in the url matches with an anchor on page
    // and scroll to it

    var newOffset,
        tabs = $('.tabs'),
        tabsHeight = 0,
        adminMenu = $('#admin-menu-wrapper'),
        adminHeight = 0,
        header = $('.sticky-top');

    // check for fixed elements on top of site
    // to compensate offset of the anchor when scrolling
    if ( $('body').hasClass('admin-menu') ) {
      adminHeight = 28;

      if (tabs.length) {
        tabsHeight = tabs.outerHeight();
      }
    }

    // only add offset if fixed header and/or value passed in function
    if (typeof offset == 'undefined') offset = 0;

    if (headerFixed) {
      offset = header.outerHeight() + offset + adminHeight + tabsHeight; // compensate for fixed header height
    } else {
      offset = 15 + offset + adminHeight + tabsHeight; // compensate with bit of space above the element with the anchor
    }

    // need negative value for the calculations later on
    offset = -(offset);

    // get hash from page url
    var urlHash = window.location.hash;

    if ( $(urlHash).length ) {
      var id = urlHash.split('#')[1];
      // el, offset, speed, callback
      self.scrollTo($(urlHash), offset, 1000 );
    }

    // when clicking an anchor, animate scroll to that anchor and add to history

    $('a[href*="#"]').not('a[href="#"]').once('js-once-scrollable-anchors').each(function() {

      // click anchor link and animate scroll to it
      $(this).on('click', function (e) {
        var path = this.href;

        // get ID/hash from url
        var id = e.target.href.substring(e.target.href.indexOf("#")+1),
            hash = '#' + id,
            target = $(hash);

        // only add offset if fixed header
        if (headerFixed) {
          newOffset = -(header.outerHeight());
          if (newOffset < offset) offset = newOffset;
        }

        if (target.length) {
          // el, offset, speed, callback
          self.scrollTo(target, offset, 1000, function () {
            // change url without refresh
            document.location.hash = hash;
          } );

          // If supported by the browser we can also update the URL
          if (window.history && window.history.pushState) {
            history.pushState("", document.title, hash);
          }

        // if no anchor with id matchin href
        // check for anchor with a name matching the href
        } else {
          var newTarget = $('a[name="' + id + '"]');

          // el, offset, speed, callback
          self.scrollTo(newTarget, offset, 1000, function () {
            // change url without refresh
            document.location.hash = hash;
          } );
        }

        // the url, minus stuff after hash or parameters
        var currentUrl = window.location.href.split(/[?#]/)[0];
        // the path, minus stuff after hash or parameters
        var pathBase = path.split(/[?#]/)[0];

        // if path points to current page, prevent reload.
        // meaning, if the url's (stripped of hashes and parameters) match up,
        // we're on the same page and don't need a page reload
        // if (currentUrl.indexOf(pathBase) !== -1) {
        if (currentUrl.replace(/\/$/, "") == pathBase.replace(/\/$/, "")) {
          e.preventDefault();
        }
      });
    });

    // when going back/forward in history
    // make sure the events associated with the anchor animations are fired
    window.onpopstate = function(event) {
      $('a[href*="#"]').not('a[href="#"]').each(function() {
        var path = this.href;
        var Current = window.location.pathname;
        // get hash from page url
        var urlHash = window.location.hash;
        // get hash & ID from href
        var linkHash = path.substring(path.indexOf("#"));

        if ($(linkHash).length) {
          // if hash of current path matches hash of the url
          // scroll to it
          if (urlHash == linkHash) {
            // el, offset, speed, callback
            self.scrollTo($(linkHash), offset, 1000);
          }
        }
      });
    };

  };

  /**
   * Make iFrames responsive
   *
   */
  self.setResponsiveFrames = function(iFrames) {

    iFrames.once('js-once-set-fluid-iframes').each(function() {

      var iFrame = $(this);

      // if no responsive wrapper, and if in .page, make a wrapper
      //
      if(iFrame.closest('.page').length && !iFrame.closest('div').hasClass('fluid-frame')) {
        self.responsiveFrames(iFrame);
      }
    });

  };

  self.responsiveFrames = function(iFrame) {

    var iWidth = iFrame.attr('width'),
        iHeight = iFrame.attr('height'),
        wrapper =  '<div class="iframe-responsive"></div>';

    if (iWidth.length && iHeight.length) {
      var padding = iHeight/iWidth*100;
      wrapper =  '<div class="iframe-responsive" style="padding-bottom:' + padding +'%"></div>';
    }

    iFrame.wrap(wrapper);

  };

  /**
   * Make tables responsive
   *
   */

  self.setResponsiveTables = function(tables) {

    // Add responsive functionality to tables added via WYSIWYG
    // or otherwise inserted in the page (eg. from Commerce)

    // if coming from CKE
    var ckeClass = '.cke_show_borders, .field-type-text-with-summary, .field-type-text-long, .views-field-body, .rte';

    tables.once('js-once-set-responsive-tables').each(function() {

      var table = $(this);

      // if already has a responsive class, define the type
      // based on if it's coming from CKE or not
      if(table.closest('div').hasClass('table-responsive')) {
        if (table.closest(ckeClass).length) {
          table.wrap('<div class="table-responsive has-scroll"></div>');
        } else {
          table.wrap('<div class="table-responsive is-reformatted"></div>');
          self.responsiveTables(table);
        }
      }

      // if no responsive wrapper, and if in .page, make a wrapper and set a type
      //
      if(table.closest('.page').length && !table.closest('div').hasClass('table-responsive')) {

        if (table.closest(ckeClass).length) {
          table.wrap('<div class="table-responsive has-scroll"></div>');
        } else {
          table.wrap('<div class="table-responsive is-reformatted"></div>');
          self.responsiveTables(table);
        }

      }

    });
  };

  self.responsiveTables = function(table) {

    // make data-attributes for CSS to use as headings
    // if th's in thead
    if(table.find('thead').length) {
      var headings = [];
      table.find('th').each(function(){
        headings.push($(this).text());
      });
      var count = 0;
      table.find('tr').each(function(){
        // table.find('td').attr('data-title', headings[count-1]);
        // ++count;
        count = 0;
        $(this).find('td').each(function() {
          $(this).attr('data-title', headings[count]);
          ++count;
        });
      });
    } else {
      // if th's in tbody
      if(table.find('th').length) {
        table.find('tr').each(function(){
          var heading = $(this).find('th').text();
          table.find('td').each(function(){
            table.attr('data-title', heading);
          });
        });
      // if no th's at all, don't need certain styling on mobile
      } else {
        table.addClass('no-th');
      }
    }
  };

  /**
   * Mobile menu functionality
   *
   */
  self.mobileMenu = function(trigger, wrapper) {

    // open/close wrapper
    trigger.once('js-once-mobilemenu').click(function(e) {
      // check for screen size bigger than phone
      if(self.screen == 'xs'/* || self.screen == 'sm'*/) {
        // add classes (css handles the animation & open/close)
        if (wrapper.hasClass('js-open')) {
          wrapper.removeClass('js-open');
        } else {
          wrapper.addClass('js-open');
        }
      }

      e.preventDefault();
    });

    // remove the close class when switching to bigger screen
    // we don't need it there
    $(window).on('resize', function() {
      if(self.screen != 'xs'/* && self.screen != 'sm'*/) {
        if (wrapper.hasClass('js-open')) {
          wrapper.removeClass('js-open');
        }
      }
    });
  };

  /**
   * Open new levels op menu
   *
   * parameters:
   * nav: navigation wrapper
   * interaction: 'click' or 'hover' to open the dropdowns
   * icon: do we need a seperate icon as a trigger?
   *       If not, the link itself is blocked on touch
   *
   */
  self.dropdownMenu = function(nav, interaction, icon) {

    var menu =  nav.children('ul'),
      firstLevel = menu.children('li');

    // find the first level children of an menu

    firstLevel.once('js-once-dropdown').each(function() {

      var item = $(this),
        itemLink = item.children('a, span'),
        trigger;

      // if any item has a submenu

      if (item.hasClass('expanded')) {

        // if there's an icon needed for opening/closing
        // render it here and use that as trigger

        if (typeof icon !== 'undefined' && icon) {
          // add extra html for arrows
          item.append('<i class="js-trigger"></i>');
          // icon becomes trigger
          trigger = item.find('.js-trigger');
        } else {
          // if no icon, the menu item itself becomes the trigger
          trigger = item;
        }

        // touch detection for li only

        if (trigger !== item || interaction == 'click') {
          item.on('touchstart', function() {
            self.touch = true;
          });

          item.on('touchend', function() {
            // end
            setTimeout(function() {
                self.touch = false; // reset bc we might be on a device that has mouse as well as touch capability
            }, 500); // time it until after a 'click' would have fired on mobile (>300ms)
          });
        }

        // touch event to open/close
        // includes touch detection

        trigger.on('touchstart', function(e) {
          self.touch = true;

          if (item.hasClass('js-open')) {
            self.navPrimaryClose(item);
          } else {
            self.navPrimaryOpen(item);
          }

          e.preventDefault();
        });

        if (itemLink.is('span')) {
          itemLink.on('touchstart', function(e) {
            self.touch = true;

            if (item.hasClass('js-open')) {
              self.navPrimaryClose(item);
            } else {
              self.navPrimaryOpen(item);
            }
          });
        }

        // reset the touch variable afterwards

        trigger.on('touchend', function(e) {

          // end
          setTimeout(function() {
              self.touch = false; // reset bc we might be on a device that has mouse as well as touch capability
          }, 500); // time it until after a 'click' would have fired on mobile (>300ms)

          e.preventDefault();

        });

        if (interaction == 'hover') {

          // open/close on hover
          // if not in touch modus

          item.on('mouseenter', function(e) {

            // if no touch triggered
            if (!self.touch) {

              self.navPrimaryOpen(item);

              e.preventDefault();

            }

          });

          item.on('mouseleave', function(e) {

              self.navPrimaryClose(item);

            e.preventDefault();
          });

        } else {

          // click to open

          itemLink.on('click', function(e) {

            // if no touch triggered
            if (!self.touch) {
              if (item.hasClass('js-open')) {
                self.navPrimaryClose(item);
              } else {
                self.navPrimaryOpen(item);
              }
              e.preventDefault();
            }

          });

          // click outside to close

          $(document).mouseup(function (e) {

            if (item.hasClass('expanded')) {
              if (item.hasClass('js-open')) {

                // if the target of the click isn't the container...
                // ... nor a descendant of the container
                if (!item.is(e.target) && item.has(e.target).length === 0)
                {
                  self.navPrimaryClose(item);
                }
              }
            }
          });
        }
      }
    });

    // on window resize, reset the menu to closed state

    var updateLayout = function(e) {

      firstLevel.each(function() {

        var firstLevel = $(this);

        self.navPrimaryClose(firstLevel);
      });
    };

    window.addEventListener('resize', updateLayout, false);
  };

  self.navPrimaryOpen = function(item)
  {
    item.addClass('js-open');
  };

  self.navPrimaryClose = function(item)
  {
    item.removeClass('js-open');
  };

  /**
   * Add search functionality
   *
   */
  self.searchHeader = function(block, iconOnly) {

    var form = block.find('form'),
        searchIcon = block.find('.icon-search'),
        cancelIcon = block.find('.icon-cancel'),
        input = block.find('input[type="text"]', 'input[type="search"]'),
        reset = true,
        keepOpen = false;

    // if the layout is only an icon, we need to add js classes
    // to be able to make the input field visible and prevent submit
    if (iconOnly) {

      // if search is inside the navigation panel, on phone
      // make it open by default
      // prevent it from closing by storing the status in a variable
      // and checking it in the reset functions
      if (block.closest('.wrapper-navigation').length && self.screen == 'xs') {
        keepOpen = true;
        block.addClass('js-open');
        block.addClass('js-active');
      }

      searchIcon.off().on('click', function(e) {

        if(block.hasClass('js-open') ) {

          reset = false; // prevent sRest from triggering when the input blurs
          form.submit();

        } else {
          block.addClass('js-open');
          self.body.addClass('js-header-search-open');
          input.focus();
        }

        e.preventDefault();
        return false; // prevent a form submit in case of input/button click

      });
    }

    var sReset = function() {
      if(reset === true) {
        if (iconOnly) {
          if (keepOpen === false) {
            block.removeClass('js-open');
            self.body.removeClass('js-header-search-open');
          }
          input.val('');
        } else {
          // reset only if no content in it
          if ( input.val().length < 1 ) {
            block.removeClass('js-active');
          }
        }
      }
    };

    // turn off any prev set events
    input.off();

    // active on focus
    input.on('focus', function(e) {

      block.addClass('js-active');

      e.preventDefault();
    });

    // reset on blur
    input.on('blur', function(e) {

      // delay to give user time to click the submit button
      if (iconOnly) {
        setTimeout(function(){
          sReset();
        }, 250);
      } else {
        sReset();
      }

      e.preventDefault();
    });

    // reset on click reset-icon
    cancelIcon.off().on('click', function(e) {

      sReset();

      e.preventDefault();
      return false; // prevent a form submit in case of input/button click
    });

    // reset when clicking outside the form
    $(document).on('click', function(e) {

       // if the target of the click isn't the container...
       // ... nor a descendant of the container
      if (!block.is(e.target) && block.has(e.target).length === 0) {
        sReset();
      }
    });

    // reset on window resize & scroll

    $(window).on('resize', function() {

      if (block.closest('.wrapper-navigation').length && self.screen == 'xs') {
        keepOpen = true;
        block.addClass('js-open');
        block.addClass('js-active');
      } else {
        keepOpen = false;
      }

      if (block.hasClass('js-open')) {
        sReset();
      }
    });

    $(window).on('scroll', function() {

      if (block.closest('.wrapper-navigation').length && self.screen == 'xs') {
        keepOpen = true;
        block.addClass('js-open');
        block.addClass('js-active');
      } else {
        keepOpen = false;
      }

      if (block.hasClass('js-open')) {
        sReset();
      }
    });
  };

  /**
   * fixedTop (with custom JS)
   *
   * Set a fixed element while scrolling e.g. a fixed header
   */
  self.fixedTop = function(pin, offsetElement) {

    // keep track of scrolling (needed for fixed stuff);
    self.scrollHandling();

    if (typeof offsetElement == 'undefined') {
      offsetElement = false;
    }

    // on window resize, check again for the right position
    // and recalculate the height
    $(window).on('resize', function() {
        self.handleStickyHeader(pin, offsetElement);
    });

    // same on scroll

    $(window).scroll(function() {
        self.handleStickyHeader(pin, offsetElement);
    });
  };

  self.handleStickyHeader = function(pin, offsetElement) {

    // if scrolling below the height of the top navigation,
    // make pin fixed

    var spacer = $('.sticky-spacer'),
        pinHeight = pin.outerHeight(),
        offset;

    if (offsetElement === false) offset = 0;
    else offset = offsetElement.outerHeight();

    // scrolling below the page top
    if (self.getScrollTop() > 0) {
      // only set fixed if we're below the body offset
      if (!pin.hasClass('is-fixed') && self.getScrollTop() >= offset) {
        pin.addClass('is-fixed');
        spacer.height(pinHeight);
      }
    // but only reset the fixed pin to default when we're at the top of the page (ignore body offset)
    } else {
      if (pin.hasClass('is-fixed')) {
        pin.removeClass('is-fixed');
        spacer.height(0);
      }
    }

    var headerTop = $('.header-top'),
        topHeight = 42;

    if (typeof headerTop != 'undefined') topHeight = $('.header-top').outerHeight();

    // check if we're at top of the page or not
    if (self.getScrollTop() > offset + topHeight) {
      pin.addClass('not-top');
    } else {
      pin.removeClass('not-top');
    }
  };

  /**
   * fixedSidebar
   *
   * Set a fixed sidebar while scrolling
   */
  self.fixedSidebar = function(pin, stop) {

    // keep track of scrolling (needed for fixed stuff);
    self.scrollHandling();

    var pinTop = pin.offset().top;

    // on window resize, check again for the right position
    // and recalculate the height
    // but only if we're not on a phone
    $(window).on('resize', function() {
      // reset pin
      pin.css('top', '0');
      pinTop = pin.offset().top;

      if(self.screen != 'xs') {
        self.handleStickySidebar(pin, pinTop, stop);
      }
    });

    // same on scroll
    $(window).scroll(function() {
      if(self.screen != 'xs') {
        self.handleStickySidebar(pin, pinTop, stop);
      }
    });
  };

  self.handleStickySidebar = function(pin, pinTop, stop) {
    // if scrolling below the page top or fixed header (page top + height fixed header),
    // make pin fixed

    var scrollTop = self.getScrollTop(),
        pinContent = pin.find('.row--main__aside'),
        pinContentHeight = pinContent.outerHeight(),
        pinBottom = pinTop + pinContentHeight,
        sidebarBottom = (pin.offset().top + pinContentHeight),
        doormatTop = stop.offset().top;

    var offset = 0;

    // scrolling below the page top
    // and above the doormat
    if (scrollTop > 0) {

      // only set fixed if we're below the body offset
      if (!pin.hasClass('is-fixed') && self.getScrollTop() >= offset) {
        pin.addClass('is-fixed');
      }

      // as long as sidebar is above the doormat
      if (pinBottom < (doormatTop - scrollTop)) {

        // move the sidebar using css top when fixed
        if (pin.hasClass('is-fixed')) {
          pin.css({'top': scrollTop + 'px'});
        }

      // when sidebar matches up with doormat,
      // keep it in place
      } else {
        //
      }

    // but only reset the fixed pin to default when we're at the top of the page (ignore body offset)
    } else {
      if (pin.hasClass('is-fixed')) {
        pin.removeClass('is-fixed');
        pin.css({'top': 0});
      }
    }
  };

  /**
   * scrollHandling
   *
   * Adds various classes to track scrolling
   */
  self.scrollHandling = function() {

    // make sure this function hasn't already been fired
    if ( self.scrollHandlingVar === false ) {

      self.scrollHandlingVar = true;

      // at page load, only set scrolling stuff if scrolled partly down the page
      if (self.getScrollTop() > 28) {
        self.body.addClass('js-scrolling').addClass('js-scrolling-up');
      }

      $(window).scroll(function() {
          self.scrollDirection();
      });
    }
  };

  self.scrollDirection = function() {

    var st = self.getScrollTop();

    // we are scrolling down from our previous position
    if (st > self.lastScrollTop){

      // we are scrolling past the page top
      if (st > 0) {
        self.body.addClass('js-scrolling').removeClass('js-scrolling-up').addClass('js-scrolling-down');
      }

    // we are scrolling up
    } else if (st < self.lastScrollTop) {

      // we are scrolling past the page top
      if (st > 0) {
        self.body.addClass('js-scrolling').removeClass('js-scrolling-down').addClass('js-scrolling-up');

      // we're at the top, reset the body classes
      } else {
        self.body.removeClass('js-scrolling').removeClass('js-scrolling-up').removeClass('js-scrolling-down');
      }
    }

    self.lastScrollTop = st;
  };

  /*
   *
   * Open/close FAQ items
   */
  self.faqCollapsable = function(faq) {

    faq.once('js-once-faq-collapsable').each(function() {

      var faqItem = $(this),
        trigger = faqItem.find('.field-name-title-field'),
        target = faqItem.find('.field-name-field-faq-body, .field-name-body');

      // alternative field names for faq title
      if (typeof trigger == 'undefined' || trigger.length < 1) {
        trigger = faqItem.find('h2');
      }

      trigger.on('click', function() {

        // close item
        if (faqItem.hasClass('js-open')) {
          faqItem.removeClass('js-open');
          target.slideUp(250, function() {
            //callback
          });
        // open item
        } else {
          faqItem.addClass('js-open');
          target.slideDown(250, function() {
            //callback
          });

          // close all siblings
          faqItem.siblings().each(function() {
            var sibling = $(this);

            if (sibling.hasClass('js-open')) {
              sibling.removeClass('js-open');
              sibling.find('.field-name-body, .field-name-field-faq-body').slideUp(250, function() {
                //callback
              });
            }
          });
        }

      });

    });

  };

  /*
   * make teaser images clickable, if no link around them but has a 'read more'
   */

  self.teaserLink = function(teasers) {

    teasers.each(function() {

      var teaser = $(this),
          img = teaser.find('img'),
          // look for last link
          link = teaser.find('a').last(),
          hrefProp = link.attr('href'),
          targetProp = link.attr('target');

      // if no link around img
      if ( img.closest('a').length < 1 && typeof hrefProp != 'undefined' && hrefProp !== null ) {

        // if a target prop exists, add it to the link
        if (typeof targetProp != 'undefined' && targetProp.length !== null) {
          // wrap image in the foundlink
          img.wrap( '<a href="' + hrefProp + '" target="' + targetProp + '"></a>' );
        } else {
          // wrap image in the foundlink
          img.wrap( '<a href="' + hrefProp + '"></a>' );
        }
      }
    });
  };

  /*
   * Set a class on content based on the color of the background
   * https://github.com/kennethcachia/Background-Check
   */
  self.backgroundCheck = function(targetSelector, imageSelector) {

    BackgroundCheck.init({
      targets: targetSelector,
      images: imageSelector
    });

    $(window).on('resize', function() {
      BackgroundCheck.refresh();
    });
  };

  /*
   * Show images once they are above the fold
   */
  self.fakeLazyLoad = function(offset) {

    // on page load, get the bottom of the page and top position of every image
    // give a 'is-hidden' class if below the fold
    // on page scroll or resize, remove the class to show the images
    // add a bit of animation via CSS

    var newKey,
        first = true,
        winHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    if (typeof offset == 'undefined') offset = 0;

    // get the key of object on which masonry was triggered, from the drupal Settings
    for (var key in Drupal.settings.masonry) {
     if (Drupal.settings.masonry.hasOwnProperty(key)) {
        newKey = key;
     }
    }

    // need to be sure it is triggered at this point
    $(key).masonry();


    // hide / show depending on position to the page bottom (fold)
    // hide only once
    var iterateImages = function () {

      var sum = 0;

      $.each($(key).find('.field-item'), function (i, val) {
        var item = $(this),
            itop = item.offset().top;

        // only need to hide stuff the first time we're doing this
        if (itop > self.getScrollTop() + winHeight - offset) {
          if (!item.hasClass('is-hidden') && first) {
            item.addClass('is-hidden');
          }
        // show and keep items visible once above the fold
        } else {
          if (item.hasClass('is-hidden')) item.removeClass('is-hidden');
          if (!item.hasClass('is-visible')) item.addClass('is-visible');
        }

        // save at end of loop
        // count how many items we've already iterated, to keep track
        sum = sum + 1;
        if ( sum == $(key).find('.field-item').length - 1 ) {
          first = false;
        }

      });
    };

    // on complete
    $(key).masonry( 'once', 'layoutComplete', function() {
      iterateImages();
    });

    // on scroll or resize
    // check images again, and show the ones coming above the fold
    $(window).on('scroll', function() {
      iterateImages();
    });

    $(window).on('resize', function() {
      iterateImages();
    });
  };

  /*
   * remove inline img style dimensions from CKEditor
   * especially for images inserted usine IMCE button
   */
  self.imgCaption = function(cke) {

    var img = cke.find('img'),
        imgClass = false;

    // if images and they are not wrapped in a figure tag
     if ( img.length ) {

      // go over each image
      img.each(function(){

        var img = $(this),
            title = img.attr('title'); // find title attribute

        // if the image has a title and is not already wrapped in a figure tag
        if (title.length && typeof title != 'undefined' &&  img.parent('figure').length < 1) {

          // wrap the image in a figure tag and add the title as a caption
          img.wrap('<figure></figure>');
          $('<figcaption>' + title + '</figcaption>').insertAfter(img);

          // check for alignment classes
          if (img.hasClass('align-left')) imgClass = 'align-left';
          if (img.hasClass('align-right')) imgClass = 'align-right';
          if (img.hasClass('align-center')) imgClass = 'align-center';

          // put them on the figure instead of the img if there are any
          if ( imgClass !== false) {
            img.removeClass(imgClass).closest('figure').addClass(imgClass);
          }
        }

      });
    }
  };

  self.tagsToDropdown = function(tags) {

    var items = tags.find('.views-field'),
        content = tags.find('.view-content'),
        dropdown = $('<select></select>'),
        i;

    // loop all tags
    for (i=0; i < items.length; i++) {

      // save state, text, link, …
      var item = items.eq(i),
          link = item.find('a'),
          text = link.text(),
          url = link.attr('href');
      // make an option with these attributes worked in there
      var option = $('<option value="' + url + '">' + text + '</option>');

      // set selected if item has active link
      if (link.hasClass('active')) {
        option.attr('selected', true);
      }

      dropdown.append(option);
    }

    content.append(dropdown);

    dropdown.on('change', function() {
      window.location.href = dropdown.val();
    });

  };

  ///////////////////////////////////////////////////////////////////////
  // Behavior for Forms: triggers
  ///////////////////////////////////////////////////////////////////////

  Drupal.behaviors.cooldropsUIForms = {
    attach: function (context, settings) {

      var input = $('input', context),
          select = $('select', context),
          textarea = $('textarea', context);

      // handle focus on form elements
      if (input.length || textarea.length) self.focusFields(input, textarea, select);

      // wrap select tag to add custom styling and arrow
      if (select.length) self.customSelect(select);
    }
  };

  ///////////////////////////////////////////////////////////////////////
  // Behavior for Forms: functions
  ///////////////////////////////////////////////////////////////////////

  /**
   * Add focus styles to form elements
   *
   */
  self.focusFields = function (input, textarea, select) {

    // if input exists
    if (input.length) {
      // iterate all inputs
      input.once('js-once-input').each(function() {
        var input = $(this);

        var wrapper = '.form-wrapper';

        if ( input.closest(wrapper).length < 1 ) {
          wrapper = '.form-item';
        }

        // add an active class on wrapper and label if focus
        input.focus(function(e) {
          var input = $(this);
          input.closest(wrapper).addClass('is-active').find('label').addClass('is-active');

          // some forms don't have wrappers with a class,
          // just use parent instead
          if (input.attr('name') == 'search_block_form') {
            input.parent().addClass('is-active').find('label').addClass('is-active');
          }

        // remove active class on blur
        }).blur(function(e) {
          var input = $(this);

          input.closest(wrapper).removeClass('is-active').find('label').removeClass('is-active');
          if (input.attr('name') == 'search_block_form') {
            input.parent().removeClass('is-active').find('label').removeClass('is-active');
          }

          // if field has a value, add a has-value class (handy for floating labels)
          if (input.val()) {
            input.closest(wrapper).addClass('has-value').find('label').addClass('has-value');
            if (input.attr('name') == 'search_block_form') {
              input.parent().addClass('has-value').find('label').addClass('has-value');
            }
          // remove value class if empty
          } else {
            input.closest(wrapper).removeClass('has-value').find('label').removeClass('has-value');
            if (input.attr('name') == 'search_block_form') {
              input.parent().removeClass('has-value').find('label').removeClass('has-value');
            }
          }
        });

        // if field has a value, add a has-value class (handy for floating labels)
        if (input.val()) {
          input.closest(wrapper).addClass('has-value').find('label').addClass('has-value');
          if (input.attr('name') == 'search_block_form') {
            input.parent().addClass('has-value').find('label').addClass('has-value');
          }
        }
      });
    }

    if (textarea.length) {
      textarea.once('js-once-textarea').each(function() {
        var textarea = $(this);

        var wrapper = '.form-wrapper';

        if ( textarea.closest(wrapper).length < 1 ) {
          wrapper = '.form-item';
        }

        // add an active class on wrapper and label if focus
        textarea.focus(function(e) {
          var textarea = $(this);
          textarea.closest(wrapper).addClass('is-active').find('label').addClass('is-active');

        // remove active class on blur
        }).blur(function(e) {
          var textarea = $(this);
          textarea.closest(wrapper).removeClass('is-active').find('label').removeClass('is-active');

          // if textarea has a value, add a has-value class (handy for floating labels)
          if (textarea.val()) {
            textarea.closest(wrapper).addClass('has-value').find('label').addClass('has-value');
          // remove value class if empty
          } else {
            textarea.closest(wrapper).removeClass('has-value').find('label').removeClass('has-value');
          }
        });

        // if field has a value, add a has-value class (handy for floating labels)
        if (textarea.val()) {
          textarea.closest(wrapper).addClass('has-value').find('label').addClass('has-value');
        }
      });
    }

    if (select.length) {
      select.once('js-once-select').each(function() {

        var select = $(this);

        var wrapper = '.form-wrapper';

        if ( select.closest(wrapper).length < 1 ) {
          wrapper = '.form-item';
        }

        select.focus(function(e) {
          var select = $(this);
          select.parent().parent().find('label').addClass('is-active');

        }).blur(function(e) {
          var select = $(this);
          select.closest(wrapper).removeClass('is-active').find('label').removeClass('is-active');

          if (select.val()) {
            select.closest(wrapper).addClass('has-value').find('label').addClass('has-value');
          } else {
            select.closest(wrapper).removeClass('has-value').find('label').removeClass('has-value');
          }
        });

        if (select.val()) {
          select.closest(wrapper).addClass('has-value').find('label').addClass('has-value');
        }
      });
    }
  };

  /*
   * wrap select in order to create custom styling for arrow and such
   */
  self.customSelect = function (select) {
    select.once('js-once-select-wrap').each(function() {
      if($(this).closest('.js-select').length < 1) {
        $(this).wrap('<div class="js-select"></div>');
      }
    });
  };

})(jQuery, Drupal, window, document);
